<template>
  <div class="prizes">
    <div class="prizes__tip">
      <Tip :backFn="toBeforePrize" :text="topText" />
    </div>
    <div class="prizes__message">
      <Message headline="KEIN GEWINN HEUTE" text="Scanne den QR-Code an der Eingangstür vom H&M Home Concept Store und gewinne mit etwas Glück einen Gutschein. Täglich hast du eine neue Chance teilzunehmen." :fn="toPrize" link="/about" link-text="So geht’s" />
    </div>
    <div class="prizes__footer">
      <p>Hier klicken für die <a href="https://hm-home-store-opening.com/cgu_hm-home.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a></p>
    </div>
  </div>
</template>

<script>
import Message from '@/components/Message.vue'
import Tip from '@/components/Tip.vue'
export default {
  name: 'Prizes',
  components: {
    Message,
    Tip
  },
  data() {
    return {topText: ''}
  },
  props: {
    goto: {type: Function}
  },
  mounted: function() {
    var _self = this;
    store.set('currentPage', 'Validated');
    this.s0 = store.sub(['prizeValidated'], function(v) {
      _self.topText = v?'Prize validated!':'';
    });
    ARController.makeVeryOverlay();
  },
  unmounted(){
    this.s0 && this.s0();
  },
  methods: {
    toPrize: function() {
      this.goto('prize');
    },
    toBeforePrize: function () {
      ARController.hideVeryOverlay();
      this.goto('prize');
      router.go(-1);
      setTimeout(function() {
        if(StateController.isSameHash(location.hash,'/#/ar-view')){
          router.go( -1 );
        }
      },30);
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.prizes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: $background-color;
}

.prizes__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.prizes__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px;
  text-align: center;
  font-size: 14px;

  p {
    margin: 0 auto 20px;
    max-width: 260px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    font-weight: 700;
    border-bottom: 1px solid $white;
  }
}
</style>
